import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
let idCount = 0;

@customElement("ptc-checkbox")
export class Checkbox extends LitElement {
    @property({ reflect: true })
    name: string = "";

    @property({ reflect: true })
    value: string = "";

    @property({ reflect: true, type: Boolean })
    readonly: boolean = false;

    @property()
    testId?: string;

    @property({ type: Boolean })
    set checked(checked: boolean) {
        if (this._input) {
            this._input.checked = checked;
            this._updateCheckedAttribute();
        } else if (this.updateComplete) {
            void this.updateComplete.then(() => {
                if (!this._input) return;
                this._input.checked = checked;
                this._updateCheckedAttribute();
            });
        }
    }
    get checked() {
        return this._input && this._input.checked;
    }

    readonly type = "checkbox";

    @query("input")
    private _input: HTMLInputElement;

    private _id: string;

    constructor() {
        super();
        this._id = `checkbox_${idCount}`;
        idCount++;
    }

    createRenderRoot() {
        return this;
    }

    checkValidity() {
        return this._input.checkValidity();
    }

    reportValidity() {
        return this._input.reportValidity();
    }

    setCustomValidity(val: string) {
        return this._input.setCustomValidity(val);
    }

    private _updateCheckedAttribute() {
        if (this.checked) {
            this.setAttribute("checked", "");
        } else {
            this.removeAttribute("checked");
        }
    }

    static styles = css`
        ptc-checkbox {
            display: inline-block;
            line-height: 0;
        }

        ptc-checkbox > input {
            position: absolute;
            opacity: 0;
        }

        ptc-checkbox > label {
            width: 1.5em;
            height: 1.5em;
            border: solid 1px var(--shade-2);
            border-radius: 0.5em;
            cursor: pointer;
            display: block;
            padding: 0;
            transition: border-color 0.3s;
        }

        ptc-checkbox > label > i {
            transition:
                transform 0.5s cubic-bezier(1, -0.5, 0, 1.5) -0.2s,
                color 0.3s,
                opacity 0.3s;
        }

        ptc-checkbox > input:not(:checked) + label > i {
            opacity: 0;
            transform: scale(0.5) rotate(90deg);
        }

        ptc-checkbox > input:checked + label {
            color: var(--green);
            border-color: var(--green);
        }

        ptc-checkbox-button {
            display: block;
        }

        ptc-checkbox-button > button {
            width: 100%;
            text-align: left;
        }
    `;

    render() {
        return html`
            <input
                data-testid=${ifDefined(this.testId)}
                type="checkbox"
                .name=${this.name}
                .value=${this.value}
                id=${this._id}
                ?readonly=${this.readonly}
                tabindex="-1"
                @change=${this._updateCheckedAttribute}
            /><label for=${this._id} ?disabled=${this.readonly}><i class="check"></i></label>
        `;
    }
}

@customElement("ptc-checkbox-button")
export class CheckboxButton extends LitElement {
    @property({ reflect: true })
    name: string = "";

    @property({ reflect: true })
    value: string = "";

    @property()
    label: string | TemplateResult = "";

    @property()
    buttonClass: string = "";

    @property()
    labelClass: string = "";

    @property()
    toggleClass: string = "";

    @property({ type: Boolean, reflect: true })
    readonly: boolean = false;

    @property()
    labelPosition: "before" | "after" = "before";

    @property()
    testId?: string;

    readonly type = "checkbox";

    @property({ type: Boolean })
    set checked(checked: boolean) {
        if (this._checkbox) {
            this._checkbox.checked = checked;
        } else if (this.updateComplete) {
            void this.updateComplete.then(() => {
                if (!this._checkbox) return;
                this._checkbox.checked = checked;
            });
        }
    }
    get checked() {
        return this._checkbox && this._checkbox.checked;
    }

    checkValidity() {
        return this._checkbox.checkValidity();
    }

    reportValidity() {
        return this._checkbox.reportValidity();
    }

    setCustomValidity(val: string) {
        return this._checkbox.setCustomValidity(val);
    }

    @query("ptc-checkbox")
    private _checkbox: Checkbox;

    private _toggle() {
        if (this.readonly) {
            return;
        }
        this.checked = !this.checked;
        this.dispatchEvent(new CustomEvent("input", { bubbles: true }));
        this.dispatchEvent(new CustomEvent("change", { bubbles: true }));
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <button
                type="button"
                class="horizontal center-aligning layout ${this.buttonClass}"
                style="${this.labelPosition === "after" ? "flex-direction: row-reverse" : ""}"
                @click=${this._toggle}
            >
                <div class="${this.labelClass || "label stretch ellipsis"}">${this.label}</div>
                <div class="spacer"></div>
                <ptc-checkbox
                    .testId=${this.testId}
                    .name=${this.name}
                    .value=${this.value}
                    .readonly=${this.readonly}
                    class="non-interactive ${this.toggleClass}"
                ></ptc-checkbox>
            </button>
        `;
    }
}
