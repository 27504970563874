import { property } from "lit/decorators.js";
import { Err, ErrorCode } from "@pentacode/core/src/error";
import { Request, Response } from "@pentacode/core/src/transport";
import { app } from "../init";
import { alert } from "../elements/alert-dialog";

type Constructor<T> = new (...args: any[]) => T;

export function ErrorHandling<B extends Constructor<object>>(baseClass: B) {
    class ErrorHandling extends baseClass {
        @property({ type: Boolean })
        offline = false;

        constructor(...args: any[]) {
            super(...args);
            app.api.hook = (req: Request, res: Response | null, err: Err | null) => this._requestHook(req, res, err);
        }

        private async _requestHook(_req: Request, _res: Response | null, err: Err | null) {
            this.offline = !!err && err.code === ErrorCode.FAILED_CONNECTION;

            if (err) {
                void this._handleError(err);
            }
        }

        private async _handleError(e: Err) {
            switch (e.code) {
                case ErrorCode.INVALID_SESSION:
                case ErrorCode.SESSION_EXPIRED:
                    await alert("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an!", {
                        title: "Sitzung Abgelaufen",
                    });
                    await app.logout();
                    window.location.reload();
                    break;
                default:
                    if (e.display) {
                        await alert(e.message, {
                            type: "warning",
                        });
                    }
            }
        }
    }

    return ErrorHandling;
}
