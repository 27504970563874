import { colors } from "../colors";

export enum TimeEntryType {
    Work = "working",
    Sick = "sick",
    Vacation = "vacation",
    ChildSick = "child_sick",
    SickInKUG = "sick_in_kug",
    School = "school",
    HourAdjustment = "hour_adjustment",
    VacationAdjustment = "vacation_adjustment",
    Free = "free",
    CompDay = "compday",
    ResetLedgers = "reset_ledgers",
}

export const absenceTypes = [
    TimeEntryType.Vacation,
    TimeEntryType.Sick,
    TimeEntryType.ChildSick,
    TimeEntryType.SickInKUG,
    TimeEntryType.CompDay,
];

export type AbsenceType = (typeof absenceTypes)[number];

export const timeEntryAbsenceTypes = [
    TimeEntryType.Sick,
    TimeEntryType.Vacation,
    TimeEntryType.ChildSick,
    TimeEntryType.SickInKUG,
    TimeEntryType.CompDay,
] as const;

export function timeEntryTypeColor(type: TimeEntryType) {
    switch (type) {
        case TimeEntryType.Vacation:
            return colors.blue;
        case TimeEntryType.Sick:
        case TimeEntryType.ChildSick:
        case TimeEntryType.SickInKUG:
            return colors.orange;
        case TimeEntryType.School:
            return colors.violet;
        case TimeEntryType.Free:
            return colors.grey;
        case TimeEntryType.CompDay:
            return colors.aqua;
        default:
            return "";
    }
}
