import {
    dateAdd,
    formatMonth,
    getCurrentMonthRange,
    getRange,
    getSubRanges,
    parseDateString,
    toDateString,
} from "@pentacode/core";
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { shared } from "../styles";
import { DateString } from "@pentacode/openapi";

const today = new Date();
const currentMonth = today.getMonth();
const currentYear = today.getFullYear();
const startOfMonth = getCurrentMonthRange().from;

function firstDayOfMonth(d?: DateString) {
    if (d) {
        return getRange(d, "month").from;
    }
}

@customElement("ptc-month-picker")
export class MonthPicker extends LitElement {
    @property({ type: Number })
    year: number = currentYear;

    @property({ type: Number })
    month: number = currentMonth;

    @property({ attribute: false })
    min?: DateString;

    @property({ attribute: false })
    max?: DateString;

    @property({ attribute: false })
    get date(): DateString {
        return toDateString(new Date(this.year, this.month, 1));
    }

    set date(d: string) {
        const date = parseDateString(d);

        const year = date?.getFullYear() || currentYear;
        const month = date?.getMonth() || currentMonth;

        if (year != this.year || month != this.month) {
            this.year = date?.getFullYear() || currentYear;
            this.month = date?.getMonth() || currentMonth;
            this._fireChange();
        }
    }

    static styles = [
        shared,
        css`
            :host {
                display: block;
                display: inline-flex;
                align-items: center;
                font-size: inherit;
            }

            button {
                padding: 0.2em 0.1em;
            }

            .month-select {
                font-weight: 600;
                flex: 1;
                min-width: 0;
                padding: 0.4em 0.5em;
                border: none;
                text-align: center;
                text-align-last: center;
            }
        `,
    ];

    render() {
        const from = firstDayOfMonth(this.min) || dateAdd(startOfMonth, { years: -5 });
        const to = firstDayOfMonth(this.max) || dateAdd(startOfMonth, { years: 1 });
        const months = getSubRanges({ from, to }, "month").map((r) => r.from);

        const currentMonth = firstDayOfMonth(this.date);
        const currentIdx = months.findIndex((m) => m === currentMonth);

        return html`
            <button
                class="transparent icon"
                @click=${() => this._selectMonth(months[currentIdx - 1])}
                ?disabled=${currentIdx <= 0}
            >
                <i class="angle-left"></i>
            </button>
            <select
                class="month-select plain click"
                @change=${(e: Event) => {
                    const select = e.target as HTMLSelectElement;
                    this._selectMonth(months[parseInt(select.value)]);
                }}
            >
                ${months.map(
                    (month, idx) =>
                        html`<option title=${`${month}`} .value=${idx.toString()} ?selected=${currentMonth === month}>
                            ${formatMonth(month)}
                        </option>`
                )}
            </select>
            <button
                class="transparent icon"
                @click=${() => this._selectMonth(months[currentIdx + 1])}
                ?disabled=${currentIdx >= months.length - 1}
            >
                <i class="angle-right"></i>
            </button>
        `;
    }

    private _selectMonth(month: DateString) {
        const date = parseDateString(month);
        if (date) {
            this.year = date.getFullYear();
            this.month = date.getMonth();
            this._fireChange();
        }
    }

    private _fireChange() {
        this.dispatchEvent(
            new CustomEvent("change", {
                detail: {
                    year: this.year,
                    month: this.month,
                    date: this.date,
                },
            })
        );
    }
}
