import { CryptoProvider } from "@pentacode/core/src/crypto";
const webCrypto = window.crypto && window.crypto.subtle;

export class WebCryptoProvider extends CryptoProvider {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hmac(_key: string, _input: string): Promise<string> {
        throw new Error("Method not implemented.");
    }

    async randomBytes(n: number): Promise<Uint8Array> {
        const bytes = new Uint8Array(n);
        await window.crypto.getRandomValues(bytes);
        return bytes;
    }

    async pbkdf2(password: string, salt: Uint8Array, iter: number = 100000) {
        const passBytes = new TextEncoder().encode(password);
        const baseKey = await webCrypto.importKey("raw", passBytes, "PBKDF2", false, ["deriveBits"]);

        const key = await webCrypto.deriveBits(
            {
                name: "PBKDF2",
                salt: salt,
                iterations: iter,
                hash: "SHA256",
            },
            baseKey,
            256
        );

        return new Uint8Array(key);
    }

    toBase64(bytes: Uint8Array | string) {
        return btoa(typeof bytes === "string" ? bytes : String.fromCharCode(...bytes));
    }

    fromBase64(str: string): string {
        return atob(str);
    }
}
